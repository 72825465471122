@media (min-width: 1800px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1652px !important;
    }
}

@media only screen and (max-width: 1799px) and (min-width: 1600px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1500px !important;
    }
}


@font-face {
    font-family: 'Assistant';
    src: url('../src/assets/fonts/Assistant-Bold.woff2') format('woff2'),
        url('../src/assets/fonts/Assistant-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Assistant';
    src: url('../src/assets/fonts/Assistant-ExtraBold.woff2') format('woff2'),
        url('../src/assets/fonts/Assistant-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Assistant';
    src: url('../src/assets/fonts/Assistant-Regular.woff2') format('woff2'),
        url('../src/assets/fonts/Assistant-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Assistant';
    src: url('../src/assets/fonts/Assistant-Light.woff2') format('woff2'),
        url('../src/assets/fonts/Assistant-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Assistant';
    src: url('../src/assets/fonts/Assistant-Medium.woff2') format('woff2'),
        url('../src/assets/fonts/Assistant-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Assistant';
    src: url('../src/assets/fonts/Assistant-SemiBold.woff2') format('woff2'),
        url('../src/assets/fonts/Assistant-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Formula Condensed';
    src: url('../src/assets/fonts/FormulaCondensed-Bold.woff2') format('woff2'),
        url('../src/assets/fonts/FormulaCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Formula Condensed';
    src: url('../src/assets/fonts/FormulaCondensed-Light.woff2') format('woff2'),
        url('../src/assets/fonts/FormulaCondensed-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: 'Assistant';
    background: #021761 0% 0% no-repeat padding-box;
}

.responsive-calendar {
    font-size: 9px !important;
}
.cursor-pointer{cursor:pointer;}
/* Large screens */
@media (min-width: 2500px) {
    .responsive-calendar {
        font-size: 12px !important;
    }
}




/* Modal */
.address-book-create .modal-content h2 {
    color: #e1e0e0;
}

.triage-model .modal-content .modal-header,
.deposit-request-modal .modal-header,
.bank-detail-modal .modal-header,
.deposit-request-modal .modal-header {
    background-color: #021761;
    color: #ffff;
    align-items: baseline;
    border: none;
}

.triage-model .modal-content h2 {
    color: #fff;
    font-size: 30px;
    margin: 0;
}

.triage-model .modal-title {
    margin: 0%;
}

.triage-model .modal-content {
    background: linear-gradient(0deg, #ffffff 100%, #021761 100%) !important;
    border: none;
}

.triage-model .modal-content p {
    font-size: 16px;
}

.triage-model .modal-content .modal-footer {
    background-color: #fff !important;
}

.trans-btn {
    background: transparent !important;
    border: 1px solid #6D80C4 !important;
}

input:focus-visible,
textarea:focus-visible {
    outline: none;
}

.react-calendar__tile:disabled {
    color: #000 !important;
    opacity: .7;
    padding: 10px !important;
}

.react-calendar__tile--active {
    color: green !important;
    font-weight: bold !important;
}

.css-1gtu0rj-indicatorContainer {
    color: #fff;
}

.list-data {
    position: relative;
}

.tarnsition {
    transition: all ease-in-out 0.3s;
}

.personal .react-date-picker__calendar {
    z-index: 5;
}

.cursor-pointer {
    cursor: pointer;
}


.cursor-pointer .copy-popup-text {
    position: absolute;
    left: 50%;
    top: -26px;
    transform: translateX(-50%);
    background: #fff;
    padding: 3px 8px 2px;
    border-radius: 5px;
    font-size: 12px;
    color: #000000;
    width: 50px;
    height: 21px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.copy-feedback {

    position: absolute;
    left: 50%;
    top: -26px;
    transform: translateX(-50%);
    background: black;
    border-radius: 5px;
    font-size: 12px !important;
    color: white;
    width: 50px;
    height: 26px;
    text-align: center;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

.triage-select__menu-list.css-4ljt47-MenuList {
    padding: 0%;
}

.list-data::before {

    position: absolute;
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: #56D0F7;
    top: 6px;
    left: -32px;

}

.rc-pagination-prev .rc-pagination-item-link,
.rc-pagination-next .rc-pagination-item-link {
    padding: 0%;
}

.rc-pagination-disabled .rc-pagination-item-link,
.rc-pagination-disabled:hover .rc-pagination-item-link,
.rc-pagination-disabled:focus .rc-pagination-item-link {
    color: #000 !important;
    opacity: .8;
}

.rc-pagination-disabled .rc-pagination-item-link,
.rc-pagination-item-link {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rc-pagination li {
    background-color: transparent;
    color: #fff;
}

.rc-pagination-item-active {
    background-color: #fff !important;
    color: #000000 !important;
}

.rc-pagination-prev button:after,
.rc-pagination-next button:after {
    font-size: 20px
}

.semicircle {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 25px;
    height: 15px;
    background-color: #56d0f7;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-up {
    font-size: 10px;
    opacity: 0.8;
}

.show .arrow-down,
.show_class .arrow-down {
    display: none;
}

.show .arrow-up,
.show_class .arrow-up {
    display: block;
}

.show_class .bottom-icon {
    bottom: 0 !important;
}

.input-file.cursor-pointer label img {
    cursor: pointer;
}

.history-deposit-table .action-col .badge,
.history-deposit-table .action-col .badge:focus {
    min-width: 115px;
    min-height: 31px;
    margin: 0 5px;
}
.file-type-error{margin-top: -28px;}
.triage-select__option img,
.triage-select__single-value img,
.country-code-select__option img,
.country-code-select__single-value img {
    margin-right: 5px;
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 100%;
}

.ril__builtinButtonDisabled:hover {
    opacity: .5;
    cursor: not-allowed;
}

.right-content {
    padding-left: 285px;
    padding-bottom: 80px;
    min-height: 100vh;
}

.email-address {
    font: normal normal normal 20px/26px Assistant;
    letter-spacing: 0px;
    color: #6D80C4;
}

.btn-close:hover {
    transform: rotate(360deg);
}

table.table-striped.table-bordered.table-hover {
    margin-bottom: 0%;
}
table.table .record-deleted{opacity:0.5;}
.country-code-select__menu-list {
    background-color: #000D3C !important;
}

.country-code-select__option {
    color: #fff !important;
    background: #6D80C4 !important;
}

.wallet-details .wallet-card {
    background: transparent linear-gradient(180deg, #113E7B 0%, #0B2275 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 16px #00000029;
    border-radius: 8px;
    min-height: 100px;
    overflow: hidden;
}

.triage-select__control {
    flex-wrap: nowrap !important;
}

.dropdown-item a:hover,
.dropdown a {
    color: #fff;
}

.btcs a {
    padding: 14px 30px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    text-decoration: none;
}

.btcs a span {
    font: normal normal 600 18px/36px Assistant !important;
    letter-spacing: 0px;
    color: #000000;
    line-height: 22px;
}

.alert-danger {
    word-break: break-all;
}

.wallet-details input:focus-visible {
    outline: none;
}

.navbar-toggler {
    padding: 4px 12px !important;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: #113e7b !important;
}

.wallet-details .card-body {
    padding: 21px 28px 0;
}

.dashboard.wallet-details .card-body {
    padding: 21px 15px 28px;
}


.wallet-card .about {
    margin-bottom: 46px;
    min-height: 42px;
}

.wallet-card .bottom-icon {
    position: absolute;
    bottom: -9px;
    left: 50%;
    z-index: 1;
    right: 50%;
}

.wallet-details h2 {
    font-family: 'Formula Condensed';
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 2.88px;
    color: #FFFFFF;
    margin-bottom: 50px;
}

input[type="file"],
.address input[type="file"],
.passport input[type="file"] {
    display: none;
}

.select__input {
    padding: 0% !important;
}

.select__control {
    min-height: 48px !important;
}

.camera-icon {
    cursor: pointer;
    font-size: 30px;
}

.camera-icon-dp {
    cursor: pointer;
    font-size: 15px;
}

.camera-circle {
    cursor: pointer;    
    background: #6d80c4;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    cursor: pointer;
}

.navbar-component a {
    padding: 19px 40px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.navbar-component a span {
    font: normal normal 600 18px/36px Assistant;
    letter-spacing: 0px;
    color: #000000;
    line-height: 22px;
}


.deposit-details .css-1s2u09g-control {
    border: 1px solid #6d80c4 !important;
}

.percent-records .css-1pahdxg-control {
    border-color: #6d80c4 !important;
    box-shadow: 0 0 0 1px black;
}

.percent-records .triage-select__indicator svg,
.percent-records .triage-select__indicator:hover svg {
    color: #7b7878;
}

.percent-records input {
    background-color: transparent !important;
}

/* Disable Button */
button.btn-triage:disabled,
button.btn-triage[disabled],
button.btn-triage:disabled,
button.btn-triage[disabled] .btn-triage:after {
    background: transparent !important;
    border: 1px solid #6D80C4 !important;
    color: #fff;
    padding: 10px;
    cursor: not-allowed;
    padding: 19px 40px;
    border-radius: 4px !important;
}

button.btn-triage:hover:disabled span {
    color: #6D80C4 !important;
}

button.btn-triage:disabled span,
button.btn-triage:disabled .pay-span {
    color: #6D80C4 !important;
}

.modal-header .btn-close {
    box-shadow: none !important;
}

button.btn-triage[disabled]:after {
    background: transparent !important;
}

button:hover:enabled .btn-triage:after {
    background: transparent !important;
}

.toast-header .btn-close {
    transition: all ease-in-out 0.4s;
}

.toast-header .btn-close:hover {
    transform: rotate(360deg);
}

.swal2-title {
    background: #021761;
    color: #fff !important;
    font-size: 1.75rem !important;
    padding-bottom: 14px !important;
}

button.swal2-confirm.swal2-styled.swal2-default-outline,
.swal2-styled.swal2-cancel {
    padding: 12px;
    background-color: #fff !important;
    border-radius: 8px;
    border: 1px solid #e1e0e0 !important;
    color: black !important;
    min-width: 152px;
}

.camera-circle-dp {
    background: #6d80c4;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    cursor: pointer;
}

.cover-uploader {
    position: absolute;
    right: 70px;
    bottom: 100px;
}

.profile-uploader {
    position: absolute;
    top: 271px;
    left: 50%;
    transform: translate(134%, 0%);
}

button.btn-yellow.ff-lato.fw-900.transition.btn-cnct-wall.d-inline-block.align-top.btn-triage-div.btn-triage.me-4 {
    background: #DBB74C 0% 0% no-repeat padding-box;
    border: #DBB74C;
}

button.swal2-confirm.swal2-styled.swal2-default-outline,
.swal2-styled.swal2-cancel {
    transition: all ease-in-out 0.3s;
    box-shadow: none !important;
}

button.swal2-confirm.swal2-styled.swal2-default-outline:hover,
.swal2-styled.swal2-cancel:hover {
    background: #ffd046 0% 0% no-repeat padding-box !important;
    border: #DBB74C !important;
    outline: none !important;
}

.trans-btn span {
    color: #6D80C4 !important;
    line-height: 16px !important;
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
    background: #DBB74C !important;

    outline: none !important;
}

.swal2-styled.swal2-confirm:focus {
    box-shadow: none !important;
}

.warning-text {
    font-weight: bold;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    background: transparent;
    color: red !important;
}

.wallet-page .path,
.wallet-page .bottom-end {
    display: none;
}

.wrapper {

    background: #021761 0% 0% no-repeat padding-box;

    border-top: 3px solid #FFD046;
}

.wrapper.scroll-fixed {
    height: 100vh;
    overflow-y: auto;
}

.accnt-lvl-img-holder {
    border-radius: 20px;
    overflow: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.wrapper {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.wrapper .bottom-end {
    position: absolute;
    right: 0;
    top: 120px;
    z-index: 0;
}

.wrapper .path {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
}

.wrapper .complete-content {
    height: 100vh;
    overflow-y: auto;
}

.form-check-input:focus {
    box-shadow: none !important;
}

.btn-triage-div {
    position: relative;
    text-align: center;
    cursor: pointer;
    color: #000000;
}

.alert-circle-icon svg {
    color: #fff !important;
}

.cypto-modal .modal-content svg {
    color: #e1e0e0 !important;
}

.address-book-create .modal-content button.btn-close {
    background-color: transparent;
    color: #e1e0e0;

}

.graph .chartjs-render-monitor {
    height: 290px !important;
}

.percent-records input:-webkit-autofill,
.percent-records input:-webkit-autofill:hover,
.percent-records input:-webkit-autofill:focus,
.percent-records input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #ffff inset !important;
    box-shadow: 0 0 0 30px #ffff inset !important;
    -webkit-text-fill-color: #000000 !important;
    -webkit-border-color: #6d80c4;
    border-color: #6d80c4;
    border: 1px solid #6d80c4;
}


.login input:-webkit-autofill,
.login input:-webkit-autofill:hover,
.login input:-webkit-autofill:focus,
.login input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 40px #021761 inset !important;
    box-shadow: 0 0 0 40px #021761 inset !important;
    -webkit-text-fill-color: #fff !important;
    border-color: #021761;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 40px #0b2275 inset !important;
    -webkit-text-fill-color: #fff !important;
    background-color: transparent !important;
}



.btn-triage-div span {
    position: relative;
    z-index: 2;
    color: #000000 !important;
}

.btn-triage-div:hover span {
    color: #000000 !important;
}

.css-1pahdxg-control {
    background-color: transparent !important;
    border-color: inherit !important;
    box-shadow: none !important;
    border: 1px solid !important;
}

.percent-records .css-1pahdxg-control {
    border-color: #6d80c4 !important;
}

.btn-triage-div:before,
.btn-triage-div:after {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    top: 0;
    left: 0;
}

.btn-triage:hover {
    color: #000000 !important;
}

.tabs-header .nav-item {
    margin: 0 33px;
}

.tabs-header .nav-item:first-child {
    margin-left: 0%;
}

.btn-triage {

    color: #000000;
    transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
    overflow: hidden;
}

.form-switch .form-check-input:checked {
    background-color: #56d0f7;
    border-color: #56d0f7;
}

.btn-triage:hover {
    border-radius: 10px;
    color: #000000 !important;
}

.btn-triage:after {
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    border-radius: 50%;
    background: #FFD046;
    border-color: #FFD046;
    color: #000000 !important;
    transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
}

.btn-triage:hover:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.25vw;
    color: #000000;
}

.form-group {
    display: block;
    margin-bottom: 15px;
}

.form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.form-group label {
    position: relative;
    cursor: pointer;
}

.react-date-picker__calendar .react-calendar {
    /* height: 100%; */
    overflow: auto;
}
.pointer{cursor: pointer;}
.react-calendar__year-view .react-calendar__tile{padding: 10px !important;}
.react-calendar__navigation{align-items: center !important;}
.react-calendar__navigation button{padding: 0% !important;}
.react-calendar__year-view .react-calendar__tile{overflow: inherit !important;}
/* .react-calendar__navigation button{min-width: 60px !important;} */
.form-group label:before {
    content: '';
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 1px solid #FFFFFF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
}

.success-text {
    font-size: 15px;
    color: green;
}

.form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 8px;
    width: 6px;
    height: 14px;
    border: 1px solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.logout-span {
    margin: 22px 10px !important;
}

.main-page {
    background: #021761 0% 0% no-repeat padding-box !important;
    padding: 21px 50px 0 !important;
    min-width: calc(100% - 280px) !important;
}

.active-card {
    border: 1px solid #56D0F7;
    overflow: hidden;
}

.error {
    /* color: red !important; */
    /* font-size: 13px; */
    color: #dc3545 !important;
    /* font-weight: 400 !important; */
    font: normal normal normal 13px/20px Assistant !important;
}
.side-btn{
    min-width: 100px;
    margin-bottom: 15px;
}
.terms-section{
    max-width: calc(100% - 100px);
    /* min-width: calc(100% -139px); */
}
.active-card .wallet-card:hover,
.active-card .wallet-card:active {
    border: none;
}


.wallet-details .settings,
.wallet-details .dropdown button,
.wallet-details .dropdown button:active {
    transition: all ease-in-out 0.3s;
}

/* Loader */
.center-body-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 500px;
}

body {
    background-color: #202628;
}

.loader-triangle-9 {
    display: block;
    margin: 0 auto;
}

.loader-triangle-9>div {
    position: relative;
    text-align: left;
    transform-origin: 33.33% 66.66%;
}

.loader-triangle-9>div:before,
.loader-triangle-9>div:after {
    content: "";
    position: absolute;
    background: inherit;
}

.loader-triangle-9>div,
.loader-triangle-9>div:before,
.loader-triangle-9>div:after {
    width: 20px;
    height: 20px;
    border-top-right-radius: 20%;
}

.loader-triangle-9>div:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
}

.loader-triangle-9>div:after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}

.loader-triangle-9>div:first-child {
    background: #2196f3;
    transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866) translate(143.33%, 110%);
    animation: anm-TL-9-first 3s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
}

.loader-triangle-9>div:last-child {
    background: #cddc39;
    transform: rotate(0deg) skewX(-30deg) scale(1, 0.866) translate(-100%, -4%);
    animation: anm-TL-9-last 3s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
}

ul.rc-pagination.m-3 {
    width: 100%;
    padding: 0;
    margin: 0;
}
.swal2-close:hover {
    transform: none;
    background: transparent;
    color: #fff !important;
}
.swal2-close:focus{outline: none !important;box-shadow: none !important;}
.react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: thin solid #6d80c4 !important;
}
.contact-country__menu-notice--no-options{color: #fff !important;}
.rc-pagination-prev .rc-pagination-item-link,
.rc-pagination-next .rc-pagination-item-link {
    display: inline-flex !important;
}

.rc-pagination-item a {
    display: inline !important;
}

.active-card {
    border: 1px solid #56D0F7;
    border-radius: 8px;
}

.deposit-request-modal .transfer-pay {
    background: #d3dbf9;
    padding: 10px;
    border-radius: 10px;
    height: 100%;
}

.rc-pagination-next button:after,
.rc-pagination-prev button:after {
    color: #000 !important;
}

.deposit-request-modal .comparison-fee {
    font-size: 14px;
    font-weight: bold;
}

.deposit-request-modal .comparison-text {
    font-size: 18px;
    font-weight: 600;
}

.d-flex.justify-content-between.align-items-center.flex-wrap.current-rate span {
    font-size: 16px;
}

.deposit-request-modal .comparison-value strong {
    font-size: 36px;
    line-height: 44px;
}

.deposit-request-modal .comparison-value span {
    font-size: 22px;
    line-height: 30px;
}

.deposit-request-modal .modal-footer .btn-triage-div,
.bank-detail-modal .modal-footer .btn-triage-div {
    border: 1px solid #000 !important;
}

/* .deposit-request-modal .modal-footer .btn-triage-div:hover,
.bank-detail-modal .modal-footer .btn-triage-div:hover {
    border-color: transparent !important;
} */

.deposit-request-modal .receipt-uploder {
    min-height: 250px;
}

.deposit-request-modal .receipt-uploder .icon-close {
    width: 30px;
    height: 30px;
    border: 1px solid #000;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease-in-out 0.3s;
}

.deposit-request-modal .receipt-uploder .icon-close:hover {
    border-color: #ffd146;
    background: #ffd146;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.bank-detail-modal .modal-text-holder {
    padding: 0 0 20px;
}

.bank-detail-modal .copy-popped-text {
    width: 200px;
    height: 40px;
    padding: 2px 7px 3px;
    font-size: 14px;
    text-transform: Uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d4edda;
    border-radius: 7px;
    color: #155724;
    position: absolute;
    bottom: 15px;
    right: 0;
    z-index: 2;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

.bank-detail-modal .bank-detail-list {
    border: 1px solid #dfdfdf;
    border-radius: 10px;
}

.bank-detail-modal .bank-detail-list li {
    padding: 10px 60px 10px 0;
    border-bottom: 1px solid #dfdfdf;
}

.bank-detail-modal .bank-detail-list li:last-child {
    border: none;
}

.bank-detail-modal .copy-icon {
    font-size: 20px
}

.bank-detail-modal .detail-label {
    width: 100%;
    padding: 0 10px;
    font-size: 18px;
    line-height: 18px;
}

.bank-detail-modal .detail-value {
    width: 50%;
    padding: 0 10px;
    word-break: break-word;
}

.bank-detail-modal .detail-icon {
    width: 40px;
    position: absolute;
    top: 7px;
    right: 10px;
}

.history-deposit-table .action-col {
    width: 200px;
}

.history-deposit-table td {
    vertical-align: middle;
}

.history-deposit-table .action-col .badge,
.history-deposit-table .action-col .badge:focus {
    min-width: 115px;
    background: #fff;
    color: #000;
    padding: 10px;
    transition: all ease-in-out 0.3s;
    ;
}

.history-deposit-table .action-col .badge:hover {
    background: #FFD046;
    color: #000;
}

.history-deposit-table .action-col button span {
    font-size: 13.5px;
    line-height: 10.5px;
    font-weight: bold;
}

.history-deposit-table .action-col button[disabled] {
    border-color: #a5a5a5 !important;
    background: #a5a5a5 !important;
    cursor: not-allowed;
}

.history-deposit-table .action-col button[disabled] span {
    color: #fff !important;
}

.history-deposit-table .receipt-image-holder {
    width: 50px;
}

/* Check Box */
.right-label-checkbox {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.form-group .right-label-checkbox:before {
    display: none;
}

.right-label-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.right-label-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #000;
    border-radius: 4px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.right-label-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.right-label-checkbox input:checked~.checkmark:after {
    display: block;
}

.temp-drop-remove .triage-select__indicators {
    display: none;
}

.triage-select__option,
.triage-select__option--is-focused {
    color: #fff !important;
    background: #6D80C4 !important;
}

.triage-select__option:hover,
.triage-select__option--is-selected {
    background-color: #2684ff !important;
}

.triage-select__single-value {
    color: #fff !important;
}

/* Style the checkmark/indicator */
.right-label-checkbox .checkmark:after {
    left: 5px;
    top: 1px;
    width: 7px;
    height: 12px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@keyframes anm-TL-9-rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
    }
}

@keyframes anm-TL-9-first {
    0% {
        transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866) translate(143.33%, 110%);
    }

    33% {
        transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866) translate(-76.67%, -120%);
    }

    66% {
        transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866) translate(-76.67%, 112%);
    }
}

@keyframes anm-TL-9-last {
    0% {
        transform: rotate(0deg) skewX(-30deg) scale(1, 0.866) translate(-100%, -4%);
    }

    33% {
        transform: rotate(0deg) skewX(-30deg) scale(1, 0.866) translate(134%, -4%);
    }

    66% {
        transform: rotate(0deg) skewX(-30deg) scale(1, 0.866) translate(-89%, -214%);
    }
}

.submit-loader {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
    z-index: 2;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.btn-triage-div .loader {
    z-index: 2;
    position: relative;
}

.sucess-text {
    color: green;
    font-weight: 600;
}

/* Sidebar */
.sidebar {
    background: #000D3C 0% 0% no-repeat padding-box;
    min-width: 280px;
    padding: 51px 0 0 20px;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 6;
}

.header .dropdown-item:hover,
.header .dropdown-item:focus {
    background-color: transparent !important;
}

.sidebar ul {
    overflow-y: auto;
    height: calc(100% - 122px);
    overflow-x: hidden;
    padding: 0 0 90px;
}

.sidebar ul::-webkit-scrollbar {
    width: 5px;
}

.sidebar ul::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px;
}

.sidebar ul::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
    background: #6779ba;
    border-radius: 8px;
}

.sidebar .logo {
    margin-bottom: 74px;
}

.sidebar ul li {
    cursor: pointer;
    margin: 0 0 10px 0;
    transition: all ease-in-out 0.3s;
}

.sidebar ul li a {
    display: inline-flex;
    padding: 22px 10px !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.transition {
    transition: all ease-in-out 0.3s;
}

.sidebar ul li a:hover,
.active {
    background: #56D0F7 0% 0% no-repeat padding-box;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

}

.sidebar ul li a[disabled]:hover {
    background: none;
    pointer-events: none;
}

.profile-btn .dropdown button,
.profile-btn .dropdown button:active {
    height: 60px;
}

.sidebar ul li span {
    font: normal normal normal 18px/23px Assistant;
    letter-spacing: 0px;
    color: #FFFFFF;
    min-width: 102px;
}

.search-sec .dropdown-menu.show {
    inset: 35px auto auto -74px !important;
}

.active .sidebar ul li a svg {
    color: white;
}

/* Hover Effect */
.effects {
    position: relative;
}

.nav-link.active.effects::after {
    width: 0;
    height: 0px;
}

.effects:hover::after {
    width: 100%;

}

.triage-select.w-100__input-container {
    color: #fff !important;
}

.apply-btn {
    padding: 15px 15px 14px !important;
}

.effects::after {
    content: "";
    position: absolute;
    background-color: #00DFFC;
    color: #00DFFC;
    height: 3px;
    width: 0%;
    left: 0%;
    bottom: -3px;
    transition: 0.3s;
}

.login-form .forget-password {
    padding: 0% !important;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #FFD046 #ccc
}

::-webkit-scrollbar {
    width: 4px;
    height: 8px;
    border: none !important
}

::-webkit-scrollbar-track {
    background: #0B2275;
    border: none !important
}

::-webkit-scrollbar-thumb {
    background-color: #FFD046;
    border: none
}

.alert.kyc-warning {
    position: fixed;
    z-index: 10;
    top: 15px;
    left: 50%;
    -o-transform: translateX(-40%);
    -ms-transform: translateX(-40%);
    -moz-transform: translateX(-40%);
    -webkit-transform: translateX(-40%);
    transform: translateX(-40%);
}

.country-code-select__single-value span {
    color: #000 !important;
}

.country-code-select__menu-list,
.phone .country-code-select__menu-list,
.phone-number-select .country-code-select__menu-list {
    padding: 0 !important;
    background-color: #000d3c !important
}

.phone .country-code-select__option,
.phone-number-select .country-code-select__option {
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    color: #fff !important;
}
.react-date-picker__inputGroup input:focus.react-date-picker__inputGroup input:focus, .select__input:focus{border: none !important;border-color: transparent !important;}
textarea:focus,.select__control:focus,.input-div-amount input:focus,.input-field input:focus{    border-color: #56D0F7 !important;
    border: 1px solid #00dffc !important;}

.phone .country-code-select__option:hover,
.phone .country-code-select__option--is-focused,
.phone .country-code-select__option--is-selected,
.phone-number-select .country-code-select__option:hover,
.phone-number-select .country-code-select__option--is-focused,
.phone-number-select .country-code-select__option--is-selected,
.country-code-select__option:hover,
.country-code-select__option--is-selected,
.country-code-select__option--is-focused {
    background: #56cff7 !important;
    color: #000 !important;
}

.rc-pagination-prev .rc-pagination-item-link,
.rc-pagination-next .rc-pagination-item-link,
.rc-pagination-item a {
    padding: 0% !important;
}

.review-modal ul li span {
    width: 240px;
    display: inline-block;
}
.btn-submit span{font-size:18px;line-height:22px;}
/* Responsive */

@media (max-width: 1900px) {

    .total-list button span,
    .top-up-account button span {
        font: normal normal 600 15px/20px Assistant;
        letter-spacing: 0;
        color: #000;
        line-height: 18px;
    }

    .top-up-account button.btn-triage {
        padding: 12px 32px;
    }
}

@media (max-width: 1500px) {
    .responsive-calendar {
        font-size: 8px !important;
    }

    .tabs-header .nav-item {
        margin: 0%;
    }
}

@media (max-width: 1399px) {
    .terms-conditions h1 {
        font-size: 54px !important; 
    }

    .wallet-details .search-field input {
        padding: 16px 15px 16px 40px;
    }

    .input-div.exchange-details input {
        padding: 12px;
    }

    .wallet-details input {
        padding: 12px 15px 12px 40px;
    }

    .crypto-market .main-img,
    .time-execution .main-img,
    .maximize-changes .main-img,
    .smart-levels .main-img {
        width: 80%;
        margin: 0 auto;
    }

    .apply-btn {
        padding: .7rem !important;
    }

    .crypto-market h2,
    .time-execution h2,
    .maximize-changes h2,
    .smart-levels h2 {
        font-size: 40px;
    }

    .navbar-component {
        width: 60px;
        margin: 0 10px 0 0;
    }

    .navbar-component .btn-header-deposit {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        background: #FFD046 !important;
    }

    .navbar-component .btn-header-deposit span {
        font-size: 30px;
    }
}

@media (max-width: 1365px) {
    .history table {
        min-width: 518px;
    }

    .history .table thead th {
        min-width: 180px;
    }
}

@media (max-width: 1200px) {
    .responsive-calendar {
        font-size: 7px !important;
    }
}

@media (max-width: 1199px) {

    .effects:hover::after {
        width: 0;
    }

    .header .nav-link.active,
    .header .nav-link:hover {
        color: #00DFFC !important;
        font-weight: bold !important;
    }

    .sidebar {
        position: absolute;
        left: -302px;
        z-index: 3;
        transition: all ease-in-out 0.3s;
    }

    .show .sidebar  {
        left: 0;
    }
    /* .app .sidebar{left: -320px;} */
    .app .show .sidebar{left: 0;}
    .menu-icon {
        display: block;
        transition: all ease-in-out 0.3s;
    }

    .show .menu-icon {
        margin: 0 0 0 198px;
        z-index: 4;
    }
    /* .app .menu-icon{margin: 0;} */
    .show .sidebar-show  {
        display: block;
        z-index: 3;
    }

    .right-content {
        padding-left: 0%;
    }

    .crypto-market .main-img,
    .time-execution .main-img,
    .maximize-changes .main-img,
    .smart-levels .main-img {
        width: 45%;
        margin: 0 auto 40px;
    }

    .alert.kyc-warning {
        position: static;
        left: auto;
        top: auto;
        -o-transform: translateX(0);
        -ms-transform: translateX(0);
        -moz-transform: translateX(0);
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

}

@media (max-width: 991px) {

    .bank-detail-modal .detail-label {
        width: 100%;
    }

    .bank-detail-modal .detail-value {
        width: calc(1000% - 40px);
    }
}

@media (max-width: 768px) {
    .profile-btn {
        margin-right: 24px;
    }

    .responsive-calendar {
        font-size: 6px !important;
    }

    button.btn-yellow.ff-lato.fw-900.transition.btn-cnct-wall.d-inline-block.align-top.btn-triage-div.btn-triage.me-4 {
        margin-bottom: 12px;
    }

    .wallet-details .profile-btn {
        margin-right: 24px;
    }

    h1 {
        font-size: 50px;
    }

    .cover-uploader {

        position: absolute;
        right: 15px;
        bottom: 114px;
    }

    .camera-circle {
        width: 50px;
        height: 50px;
    }

    .deposit-request-modal .comparison-value strong {
        font-size: 26px;
        line-height: 30px;
    }
}

@media (max-width: 575px) {
    .search-sec .dropdown-menu.show {
        inset: 35px auto auto 0px !important;
    }

    .cover-uploader {

        position: absolute;
        right: 4px;
        bottom: 110px;
    }

    .camera-circle {
        width: 50px;
        height: 50px;
    }

    .exchange-triage .history {
        margin-bottom: 20px;
    }

    .wallet-details input {
        padding: 12px 8px;
    }

    .crypto-market h2,
    .time-execution h2,
    .maximize-changes h2,
    .smart-levels h2 {
        font-size: 30px;
    }
}

@media (max-width: 567px) {
    .navbar-component a {
        margin: 0;
    }

    .profile-btn .dropdown button,
    .profile-btn .dropdown button:active {
        height: 44px;
    }

    .wallet-details .top-head {
        padding-top: 0;
        margin-bottom: 20px;
        margin-left: 25px;
    }

    .triage-model .modal-content h2 {
        color: #fff;
        font-size: 18px !important;
    }

    .profile-btn .dropdown button {
        min-width: auto;
        padding: 0 10px !important;
    }
}
@media (max-width: 480px) {
     .terms-section{width: 100%;max-width: 100%;}
}
@media (max-width: 460px) {
    
    .error{min-width: 200px;}
    .swal2-title{font-size: 1.10rem !important;}
    .sidebar ul {
        height: calc(100% - 140px);

    }
    .terms-conditions h1,.terms-conditions strong {
        font-size: 30px !important;
    }
    .react-date-picker__calendar.react-date-picker__calendar--open {
        width: 100% !important;
    }
    .security-modal .modal-title {
        font: normal normal bold 18px/17px Assistant !important;
    }

    .security-modal p,
    .security-modal span {
        font: normal normal normal 16px/17px Assistant !important;
    }

    .mb-230 {
        margin-bottom: 50px !important;
    }

    h1 {
        font-size: 36px !important;
        font-weight: 40px;
    }

    h2 {
        font-size: 26px !important;
    }

    .earn .separator {
        display: none;
    }

    .earn .price {
        width: 100% !important;
    }


    .banner h1 {
        font-size: 35px !important;
        width: 100% !important;
    }

    .lower-footer .copyright {
        font: normal normal normal 12px/50px Assistant;
        line-height: 16px;
    }

    .banner span {
        font-size: 20px !important;

    }

    a {
        padding: 8px 17px !important;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    a span {
        font: normal normal 600 14px/36px Assistant !important;
        line-height: 18px !important;
    }

    .earn .percent {
        font-size: 54px;
    }

    .banner span {
        width: 100% !important;
        line-height: 23px !important;
    }

    .banner span {
        font-size: 15px !important;
    }

    .upper-footer button {
        padding: 4px !important;
    }

    .navbar-component {
        width: 40px;
    }

    .navbar-component a {
        padding: 0 !important;
    }

    .navbar-component .btn-header-deposit span {
        font-size: 20px !important;
    }
}

@media (max-width: 380px) {

    p,
    ul li {
        font-size: 14px !important;
    }

    .profile-uploader {
        position: absolute;
        top: 271px;
        left: 50%;
        transform: translate(90%, 0%);
    }
}

@media (max-width: 360px) {
    .input-div.exchange-details input {
        padding: 9px;
    }

    .triage-select__single-value {
        color: #fff !important;
        font-size: 10px;
    }

    .email-address {
        font: normal normal normal 13px/18px Assistant;
    }

    .input-tag {
        width: 50px;
    }

    .lower-footer .copyright {
        font: normal normal normal 9px/50px Assistant;
        line-height: 16px;
    }

    .additional-files .card span,
    .address .card span,
    .passport .card span {
        font: normal normal normal 10px/14px Assistant !important;
    }
}

@media (max-width: 340px) {
    .wallet-details .profile-btn {
        margin-right: 0%;
        padding-bottom: 10px;
    }

    .navbar-component {
        margin-bottom: 10px;
    }
}
@media (max-width:320px) {
.form-group input:checked+label:after{top: 2px;}}
@media (max-width:319px) {
    .navbar-component {
        height: 40px;
    }
    .terms-conditions h1,.terms-conditions strong {
        font-size: 25px !important;
    }
    .profile-btn .dropdown-toggle {
        display: flex;
        flex-direction: column-reverse;
        height: auto !important;
    }

    .profile-btn .dropdown button {
        padding: 3px 10px !important;
    }

    .profile-btn .dropdown-toggle img {
        margin: 0;
    }
}