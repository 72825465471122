 /* .management {
     min-height: 1422px;
 } */

 .management-details .tri-cards {
     background: transparent linear-gradient(180deg, #113E7B 0%, #0B2275 100%) 0% 0% no-repeat padding-box;
     box-shadow: 0px 3px 16px #00000029;
     width: 370px;
     min-height: 117px;
     padding: 40px 0px;
     border-radius: 8px;
     display: flex;
     justify-content: center;
     align-items: center;
 }
.pl-62{
    padding-left: 62px;
    overflow: hidden;
    /* width: 300px; */
}
 .management-details .tri-card {
     margin-bottom: 122px;
 }

 .management-details .tri-cards strong {
     font: normal normal bold 24px/17px Assistant;
     letter-spacing: 0px;
     line-height: 28px;
     color: #FFFFFF;
 }

 .management-details .graph {
     background: transparent linear-gradient(180deg, #113E7B 0%, #0B2275 100%) 0% 0% no-repeat padding-box;
     box-shadow: 0px 3px 16px #00000029;
     /* width: 1021px; */
     min-height: 395px;
     border-radius: 8px;
 }

 .management-details .graph span {
     font: normal normal medium 20px/26px Assistant;
     letter-spacing: 0px;
     color: #FFFFFF;
     display: block;
 }

 .management-details .graph .neg-value {
     font: normal normal medium 16px/21px Assistant;
     letter-spacing: 0px;
     color: #EC4E6E;
 }

 .management-details .graph .green-value {
     font: normal normal normal 18px/23px Assistant;
     letter-spacing: 0px;
     color: #1FD3A4;
 }

 .management-details .graph .head {
     padding: 30px 20px;
 }

 .graph .circle-icon {
     width: 30px;
     height: 30px;
     border-radius: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     background-color: #FFFFFF;
     margin-right: 15px;
 }

 .management-details .manual-calculations .card {
     background: transparent linear-gradient(180deg, #113E7B 0%, #0B2275 100%) 0% 0% no-repeat padding-box;
     box-shadow: 0px 3px 16px #00000029;
     /* width: 498px; */
     min-height: 118px;
     padding: 40px 20px;
     margin-bottom: 19px;

 }

 .management-details .manual-calculations .card span {
     font: normal normal normal 20px/17px Assistant;
     letter-spacing: 0px;
     color: #FFFFFF;
 }

 .tri-circle {
     width: 38px;
     height: 38px;
     border-radius: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     background-color: #FFFFFF;
     margin-right: 23px;
 }

 @media (max-width: 1900px) {
     .management-details .tri-cards {
         width: 100%;
     }

     .management-details .tri-cards {
         width: 100%;
     }

 }

 /* @media (max-width: 1580px) {
     .management-details .tri-cards {
         min-height: 151px;
         display: flex;
         justify-content: center;
     }
 }

 @media (max-width: 1199px) {
     .management-details .tri-cards {
         min-height: 117px;
     }
 } */