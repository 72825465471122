.referral {
    min-height: 800px;
    padding-bottom: 50px;
}

.referral-tree {
    background: transparent linear-gradient(180deg, #113E7B 0%, #0B2275 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 0px #00000029;
    /* min-height: 434px; */
    min-height: max-content;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 50px 0 0px 0;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 12px;
    width: 1590px;

}

.referral-tree strong {
    font: normal normal bold 20px/26px Assistant;
    letter-spacing: 0px;
    color: #FFFFFF;
    display: block;
}

.referral-tree span {
    display: block;
    font: normal normal 500 20px/26px Assistant;
    letter-spacing: 0px;
    color: #6D80C4;
}

.referral-tree .add-items {
    margin-bottom: 70px;
}

.referral-tree-structure {
    /* border-top: 1px solid #ffff; */
    /* background: #0c2576; */
    /* padding-bottom: 75px; */
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    margin: 0 100px;
}

.root-line {
    position: relative;
    padding: 40px 20px 0;
    min-width: max-content;
    /* padding: 40px 74px 0; */
    /* margin: auto; */
    /* min-width: 100px; */
}


.root-line::after {
    position: absolute;
    content: '';
    border-bottom: 2px solid #6779ba;
    /* width: 238px; */
    top: 0;
    /* left: 100px; */
    /* width: 123%; */
    width: 100%;
}

.root-line:last-child::after {
    border: none;
}
/* .root-line:first-child{margin:auto;} */
.root-line:nth-child(1) .d-flex.text-center.align-items-center {justify-content: center;}
.recieved-bonus {
    /* width: 70px; */
    width: 50%;
}

.referral-tree::-webkit-scrollbar,
.referral-tree-structure::-webkit-scrollbar {
    width: 5px !important;
}

.referral-tree::-webkit-scrollbar-track,
.referral-tree-structure::-webkit-scrollbar-track {
    background-color: #fff !important;
    border-radius: 8px !important;
}

.referral-tree::-webkit-scrollbar-thumb,
.referral-tree-structure::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    background: #6779ba !important;
    border-radius: 8px !important;
}

.root-line::before {
    content: '';
    position: absolute;
    border-bottom: 2px solid #6779ba;
    width: 20px;
    top: 8px;
    left: 47%;
    /* left: 48%; */
    transform: rotate(90deg);
}

.referral-tree-structure {
    overflow: auto;
}


.referral-link {
    background: transparent linear-gradient(180deg, #113E7B 0%, #0B2275 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 16px #00000029;
    min-height: 517px;
    padding-bottom: 40px;
    margin-bottom: 72px;
    position: relative;
    border-radius: 12px;
}

.referral-link .user-details {
    border-radius: 100%;
    border: 10px solid #425B95;

}

.your-referral {
    /* margin-top: -100px; */
    margin-top: -240px;
}

.referral-link .user-details {
    position: absolute;
    left: 50%;
    /* top: 40%; */
    top: 200px;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 200px;
    height: 200px;
    /* border-radius: 100%;
    overflow: hidden; */
}

.user-detail-profile {}

.referral-link .user-details strong {
    font: normal normal bold 24px/32px Assistant;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.referral-link .header-top {
    /* background: #235D91 0% 0% no-repeat padding-box; */
    min-height: 222px;
    /* padding: 61px; */
    background: #6D80C4 0% 0% no-repeat padding-box;
    padding: 25px 61px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}

.referral-link .bottom-footer {
    background: #425B95 0% 0% no-repeat padding-box;
    min-height: 96px;
    padding: 23px 30px;
}

.referral-link .body-content {
    min-height: 230px;
}

.referral-link .bottom-footer strong {
    font: normal normal bold 20px/26px Assistant;
    letter-spacing: 0px;
    color: #FFFFFF;
    display: block;
    margin-bottom: 7px;
}

.referral-link .bottom-footer a {
    cursor: pointer;
    font: normal normal normal 14px/18px Assistant;
    text-decoration: none;
    letter-spacing: 0px;
    color: #FFFFFF;
    display: block;
}

.referral-link .header-top p {
    font: normal normal bold 24px/32px Assistant;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.referral-link .header-top strong {
    font: normal normal bold 40px/52px Assistant;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.user-details img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
}

.referral-link .header-top span {
    font: normal normal 300 14px/18px Assistant;
    letter-spacing: 0px;
    color: #FFFFFF;
    padding: 5px;
}

.arrow-down {
    position: relative;
}

.arrow-down::after {
    position: absolute;
    height: 75px;
    width: 4px;
    border-right: 3px solid #6D80C4;
    content: '';
}

.arrow-down::before {
    border: 3px solid #6D80C4;
    content: '';
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    bottom: -77px;
    /* left: 121px; */
    left: 84px;
    position: absolute;
}

.line-arrow-holder {
    padding: 0 0 100px;
    position: relative;
    margin: 0 0 50px;
}

.line-arrow-holder:before {
    content: "";
    width: 2px;
    height: 75px;
    position: absolute;
    bottom: 0;
    left: 50%;
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #fff;
}

.user-profile {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    overflow: hidden;
}

.user-profile img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.tree-profile {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
}

.tree-profile img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.line-arrow-holder:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    /* bottom: -10px; */
    bottom: 0px;
    left: 50%;
    -o-transform: translateX(-50%) rotate(45deg);
    -ms-transform: translateX(-50%) rotate(45deg);
    -moz-transform: translateX(-50%) rotate(45deg);
    -webkit-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg);
    border: solid #fff;
    border-width: 0 2px 2px 0;
}




@media (max-width: 1900px) {
    .arrow-down::before {
        /* left: 120px; */
        left: 84px;
    }
    .referral-tree{
        width: 100%;
    }
}

@media (max-width: 1399px) {
    .referral-link .user-details {
        /* top: 44%; */
        top: 36%;
    }
    .referral-tree-structure {margin: 0 40px;}
    .your-referral {
        /* margin-top: -130px; */
        margin-top: -190px;
    }

    .referral-link .header-top strong {
        font: normal normal bold 35px/39px Assistant;
    }

    .referral-link .header-top {
        padding: 25px 51px;
    }
}

@media (max-width: 1199px) {
    .referral-link .header-top {
        padding: 25px 21px;
    }

    .referral-link .header-top strong {
        font: normal normal bold 30px/34px Assistant;
    }
}

@media (max-width: 767px) {

    /* .your-referral {
    margin-top: -208px;
} */
    .referral-link .header-top p {
        font-size: 20px;
    }

    .referral-link .header-top span,
    .referral-link .header-top strong {
        font-size: 15px;
    }

    .referral-link .header-top {
        padding: 35px 21px;
    }

    .referral .link-url {
        font-size: 12px;
    }

}

@media (max-width: 575.98px) {
    .referral-link .user-details {

        /* top: 45%; */
        top: 220px;
    }
    .root-line::before{
        left: 46%;
    }

    /* .your-referral {
        margin-top: -110px;
    } */
    .referral-link .header-top p {
        font-size: 18px;
        /* line-height: 19px; */
        margin-right: 8px;
    }

    .referral-link .header-top {
        padding: 35px 8px;
    }

    .referral-link .header-top strong {
        font-size: 13px;
    }

    .referral-link .header-top span {
        font-size: 8px;
    }
    .referral-tree strong{
        font: normal normal bold 15px/20px Assistant;
    }
}

@media (max-width: 480px) {
    .referral-link .header-top p {
        font-size: 15px;
        line-height: 19px;
        margin-right: 8px;
    }
    .referral-tree strong{
        font: normal normal bold 12px/16px Assistant;
    }
}


@media (max-width: 375px) {
.referral-tree-structure {
    margin: 0 20px;
}
.root-line::before {
    left: 45%;
}
}
@media (max-width: 360px) {
    .referral-link .user-details {
        width: 150px;
        height: 150px;
    }
    /* .root-line::before{
        left: 45%;
    } */
    .your-referral {
        margin-top: -265px;
    }

    .referral .link-url {
        font-size: 8px;
    }
    .referral-tree-structure {margin: 0 20px;}
    /* .referral-link .bottom-footer{
        margin-top: 70px;
    } */
    .referral-link .user-details {
        /* top: 28%; */
        top: 210px;
    }

    .arrow-down::before {
        /* left: 90px; */
        left: 63px;
    }

    .referral-link .header-top {
        padding: 38px 10px;
    }

    .referral-link .header-top p {
        font-size: 15px;
        line-height: 19px;
        margin-right: 8px;
    }

    /* .referral-link .header-top strong {
        font-size: 30px;
    } */

    .referral-link .header-top span {
        font-size: 8px;
    }

    .referral-link .bottom-footer strong {
        font: normal normal bold 14px/26px Assistant;
    }

    .referral-link .bottom-footer a {
        font: normal normal normal 8px/18px Assistant;
    }

    .referral-link .user-details strong {
        font-size: 20px;
        line-height: 24px;
    }

    .referral-tree strong,
    .referral-tree span {
        font-size: 15px;
    }
    .referral-tree strong{
        font: normal normal bold 10px/14px Assistant;
    }
}

@media (max-width: 320px) {
    .root-line::before{
        left: 46%;
    }
/* .your-referral {
    margin-top: -142px;
} */
}
@media (max-width: 306px) {

    /* .your-referral {
    margin-top: -215px;
} */
    span {
        font-size: 12px;
    }
    .root-line::before{
        left: 44%;
    }
    .referral-tree strong{
        font: normal normal bold 8px/12px Assistant;
    }
}

@media (max-width: 280px) {

    .root-line::before{
        left: 46%;
    }
    .referral-tree strong,
    .referral-tree span {
        font-size: 12px;
    }

    .arrow-down::before {
        /* left: 71px; */
        left: 50px;
    }
}

@media (max-width: 320px) {
    .referral-link .user-details {
        top: 33%;
    }
}
@media (max-width: 280px) {
.referral-tree-structure {
    margin: 0 -10px;
}}