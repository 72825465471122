.promo-code-details .history .table thead th {
    padding: 15px 28px;
}

.promo-code-details .history .table tbody tr td {
    padding: 15px 28px;
}

.promo-code-details .enter-code-box {
    background: transparent linear-gradient(180deg, #113E7B 0%, #0B2275 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 16px #00000029;
    min-height: 218px;
    margin-bottom: 72px;
    border-radius: 8px;
    padding: 46px 85px 68px;
}

.promo-code-details .enter-code-box strong {
    font: normal normal bold 24px/32px Assistant;
    letter-spacing: 0px;
    color: #FFFFFF;
    display: block;
    margin-bottom: 16px;
}

.not-found-alert {
    border: 0;
    color:black;
    padding: 10px 15px;
    font-size: 14px;
    background-color: #56D0F7;
    border-radius: 4px;
}

.promo-code-details .enter-code-box button {
    padding: 9px 40px;
    background: #DBB74C 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: 1px solid #DBB74C;
}

.enter-input {
    width: 1146px;
    margin-right: 30px;
}

.enter-input input {
    width: 100%;
    background-color: transparent;
    border: 1px solid #6D80C4;
    color: #ffff;
    border-radius: 12px;
    padding: 10px 15px;
}

.enter-input input::placeholder {
    font: normal normal normal 20px/26px Assistant;
    letter-spacing: 0px;
    color: #6D80C4;
}

@media (max-width: 1800px) {
    .enter-input {
        width: 100%;
    }
}

@media (max-width: 567px) {
.promo-code-details .enter-code-box{
    padding: 46px 20px 68px;
}}