.smart-levels {
    min-height: 1080px;
    padding-top: 200px;
}

.smart-levels h1 {
    font-family: 'Formula Condensed';
    font-weight: bold;
    font-size: 94px;
    line-height: 98px;
    letter-spacing: 3.76px;
    color: #FAFBFF;
    text-transform: uppercase;
    margin-bottom: 40px;
}

.smart-levels h2 {
    font-family: 'Formula Condensed';
    font-weight: bold;
    font-size: 48px;
    line-height: 52px;
    letter-spacing: 3.36px;
    color: #FFFFFF;
    text-transform: uppercase;
    margin-bottom: 40px;
}

.smart-levels a ,.smart-levels button {
    padding: 18px 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: none;
    text-decoration: none;
}

.smart-levels a span ,.smart-levels button span {
    font: normal normal 600 18px/36px Assistant;
    letter-spacing: 0px;
    color: #000000;
    line-height: 22px;
}

.stages {
    margin-bottom: 40px;
}

.smart-levels .email-address {
    font: normal normal bold 20px/26px Assistant;
    letter-spacing: 0px;
    color: #FFD046;
    display: block;
    margin-bottom: 60px;
    cursor: pointer;
}

.smart-levels .level-tag {
    width: 85px;
    height: 30px;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 2px;
    /* background-color: #3fadc9; */
    background-color: #FAFBFF;
    border-radius: 24px;
}

.smart-levels .beginner .active {
    background-color: #3fadc9;
    color: #FFFFFF;
}

/* #40ad91 */
/* #e1bf5f */
/* #8f65b6 */

.smart-levels p {
    /* font: normal normal 300 26px/36px Assistant; */
    font: normal normal 300 20px/26px Assistant;
    letter-spacing: 0px;
    color: #FAFBFF;
    margin-bottom: 56px;
}

.smart-levels .card {
    /* min-height: 612px; */
    padding: 38px 28px;
    border-radius: 12px;
    transition: all ease-in-out 0.3s;
    border: none;
}

.smart-levels .card:hover {
    transform: scale(1.1);
    z-index: 1;
}

.smart-levels .card h3 {
    font: normal normal bold 34px/41px Formula Condensed;
    letter-spacing: 2.38px;
    color: #FFFFFF;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.smart-levels .card .shape {
    /* background-image: url("../../assets/images/levels-shape.svg"); */
    background-repeat: no-repeat;
    width: 100%;
    min-height: 186px;
    background-size: contain;
    width: 200px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.shape .svg {
    width: 150px !important;
}

button.smartlevel-level2-tabs.level-tag.active {
    background: #26b18e;
    color: white;
}

button.smartlevel-level3-tabs.level-tag.active {
    color: white;
    background: #c39c2a;
}
button.smartlevel-level4-tabs.level-tag.active {
    color: white;
    background: #521988;
}

.smart-levels .card span {
    font: normal normal normal 18px/23px Assistant;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-bottom: 12px;
    display: block;
}

.smart-levels .box p {
    font: normal normal normal 20px/30px Assistant;
    letter-spacing: 0px;
    color: #6D80C4;
    margin-bottom: 10px;
    width: 618px;
}

.smart-levels button span {
    font: normal normal 600 18px/36px Assistant;
    letter-spacing: 0px;
    color: #000000;
    line-height: 22px;
}

.smart-levels .box {
    background: #0B2275 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 16px #00000029;
    width: 789px;
    min-height: 180px;
    border-radius: 8px;
    margin-bottom: 30px;
    padding: 30px 24px;
}

.smart-levels .card strong {
    font: normal normal bold 24px/32px Assistant;
    letter-spacing: 0px;
    color: #FFFFFF;
    display: block;
    margin-bottom: 24px;
}

.beginner {
    background: transparent linear-gradient(180deg, #00B3DD 0%, #1A86A2 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 16px #00000029;
}

.advance {
    background: transparent linear-gradient(180deg, #1FD3A4 0%, #057155 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 16px #00000029;
}

.expert {
    background: transparent linear-gradient(180deg, #F2CB59 0%, #C79C20 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 16px #00000029;
}

.elite {
    background: transparent linear-gradient(180deg, #9166B9 0%, #5E2296 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 16px #00000029;
}

.smart-levels .why-become-triage .para {
    width: 950px;
}


.smart-levels .why-become-triage .box .box-img {
    width: 112px;
    height: 112px;
    margin-right: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.smart-levels .why-become-triage .box .box-content {
    width: calc(100% - 130px);
}


.mb-230 {
    margin-bottom: 230px;
}

.mb-100 {
    margin-bottom: 100px;
}

@media (max-width: 1800px) {

    .smart-levels .card,
    .smart-levels .why-become-triage .para {
        width: 100%;
    }

    .smart-levels .level-tag {
        width: fit-content;
    }

    .smart-levels .box {
        width: 100%;
        min-height: 186px;
    }

    .smart-levels .box p {
        width: 100%;
    }
}

@media (max-width: 1399px) {
    .smart-levels h1 {
        font-size: 75px;
        line-height: 79px;
    }

    .smart-levels h2 {
        font-size: 40px;
    }
}

@media (max-width: 1199px) {
    .smart-levels h1 {
        font-size: 65px;
        line-height: 69px;
    }

    .smart-levels .card:hover {
        transform: none;
    }

    .smart-levels p {
        font: normal normal 300 20px/36px Assistant;
    }
}


@media (max-width: 767px) {
    .smart-levels h1 {
        font-size: 45px;
        line-height: 49px;
    }

}

@media (max-width: 567px) {
.mb-100 {
    margin-bottom: 30px;
}}
@media (max-width: 479px) {
    .smart-levels .main-img.lottie-img {
        width: 100%;
    }

    .smart-levels .level-tag {
        font-size: 10px;
    }

    /* .mb-230 {
        margin-bottom: 120px;
    } */
    .smart-levels .why-become-triage .box>div {
        flex-direction: column;
    }

    .smart-levels .why-become-triage .box .box-content {
        width: 100%;
    }

    .smart-levels .why-become-triage .box .box-img {
        margin: 0 auto 20px;
    }

    .smart-levels h1 {
        font-size: 35px;
        line-height: 39px;
    }
}





/* background: transparent linear-gradient(180deg, #113E7B 0%, #0B2275 100%) 0% 0% no-repeat padding-box; */