.payment {
    min-height: 1080px;
    padding-top: 200px;
}

.payment h1 {

    letter-spacing: 3.76px;
    color: #FAFBFF;
    text-transform: uppercase;
    font-family: 'Formula Condensed';
    font-weight: bold;
    font-size: 94px;
    line-height: 98px;
    margin-bottom: 40px;
}

.payment button span {
    font: normal normal 600 18px/36px Assistant;
    letter-spacing: 0px;
    color: #000000;
    line-height: 22px;
}

.payment .arrow {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: 1px solid #000000;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.payment button {
    padding: 19px 60px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.success-msg-circle,
.error-msg-circle {
    width: 200px;
    height: 200px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 100%;
    margin-bottom: 70px;
}

.tick-icon {
    color: #fff;
    font-size: 130px;
}
@media (max-width: 320px) {
.payment h1{
    font-size: 32px !important;
    line-height: 49px;
}
}