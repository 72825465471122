.maximize-changes {
    padding: 100px 0 200px;
    background: #021761 0% 0% no-repeat padding-box;
}

.maximize-changes h2 {

    font-family: 'Formula Condensed';
    font-weight: bold;
    font-size: 48px;
    letter-spacing: 3.36px;
    color: #FFFFFF;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.maximize-changes .para {
    margin-bottom: 70px;
}

.maximize-changes p {
    font: normal normal 300 20px/26px Assistant;
    color: #FFFFFF;
    width: 673px;
}

.maximize-changes a{
    padding: 19px 52px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border: none;

}

.maximize-changes a span {
    font: normal normal 600 18px/36px Assistant;
    letter-spacing: 0px;
    color: #000000;
    line-height: 22px;
}

/*responsive*/
@media (max-width: 1700px) {
    .maximize-changes p {
        width: 100%;
    }

}
@media (max-width: 1199px) {
    .maximize-changes {
        padding: 0px 0 120px;
        background: #021761 0% 0% no-repeat padding-box;
    }
}