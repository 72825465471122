.banner-div .banner {
    position: relative;
    /* min-height: 830px; */
    /* min-height: 100vh; */
/* box-shadow: 0px 3px 36px #0000008E; */
}
.banner2 {
    position: relative;
    min-height: 1000px;
    /* min-height: 100vh; */
/* box-shadow: 0px 3px 36px #0000008E; */
}
/* .banner2 .path{
    position: absolute;
    right: 0;
}
.banner2 .bottom-end{
    position: absolute;
    right: 0%;
} */
.banner-div {
    min-height: 830px;

}

.banner-div ul li {
    margin-right: 96px;
    width: 137px;
    text-align: center;
}

.banner-div ul li span {
    /* width: 130px; */
    display: inline-block;
    text-align: center;
    font: normal normal normal 18px/23px Assistant;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.with-us {
    width: 102px;
}

#react-logo {
    position: absolute;
    right: 100px;
    top: 100px;
}

.banner span {
    font: normal normal 300 26px/36px Assistant;
    letter-spacing: 0px;
    color: #FAFBFF;
    margin-bottom: 50px;
    display: block;
}

/* .bottom-end {
    position: absolute;
    right: 0;
    top:292px
} */

.banner h1 {
    font-size: 94px;
    font-family: 'Formula Condensed';
    font-weight: bold;
    letter-spacing: 3.76px;
    color: #FAFBFF;
    text-transform: uppercase;
    margin-bottom: 50px;
    width: 936px;
}


.banner .banner-details .heading {
    margin-bottom: 134px;
}

.banner .design {
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: -1;
}

/* .banner .path {
    position: absolute;
    top: 0%;
    right: 0;
} */

/* .banner .banner-details {
    position: absolute;
    top: 60%;
    left: 29%;
    transform: translate(-50%, -50%);
    margin-bottom: 141px;
} */
.banner .banner-details{
    padding-top: 200px;
}

.banner .gif-path {
    position: absolute;
    top: 80px;
    right: 0;
    width: 870px;
    height: 583px;
    z-index: 1;
}

.banner a {
    padding: 19px 52px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border: none;
    /* color: #000000; */
}
.banner-div .banner-bottom{
    margin: 0 0 58px;
}

.banner a span {
    font: normal normal 600 18px/36px Assistant;
    letter-spacing: 0px;
    color: #000000;
    line-height: 22px;
}


@media (max-width: 1919px){
    .banner h1 {
        font-size: 80px;
    }
}
@media (max-width: 1800px) {
    .banner h1{
        width: 100%;
        font-size: 75px;
    }
    /* .banner .banner-details{
        left: 34%;
    } */
}
@media (max-width: 1600px) {
    .banner .gif-path {
        top: 160px;
        width: 670px;
        right: 20px;
    }
    .banner h1 {
        font-size: 65px;
    }
}
@media (max-width: 1399px) {
    .banner h1 {
        font-size: 54px;
    }
    .banner .gif-path {
        top: 150px;
        width: 570px;
        right: 20px;
    }
}
@media (max-width: 1199px) {
    .banner-div ul li {
        margin-right: 50px;
    }
    /* .banner-details .heading{
        padding-top:  800px !important;
        padding-bottom:  800px !important;
    } */
    .banner h1 {
        width: 90%;
    }
    .banner .gif-path {
        top: 152px;
        width: 470px;
        right: 0px;
    }
    .banner span {
        font: normal normal 300 22px/32px Assistant;
    }

}
@media (max-width: 991px) {
/* .banner .banner-details {
    left: 43%;
} */
.banner h1 {
    width: auto;
    max-width: 530px;
}
.banner .gif-path{
    display: none;
}
}

@media (max-width: 767px) {
    .banner-div ul li {
        margin-bottom: 20px;
        margin-right: 30px;
    }
    .banner h1{
        width: 100%;
    }

    .banner h1 {
        font-size: 50px;
    }
   
}

@media (max-width: 575px) {
    .banner h1 {
        font-size: 43px;
    }
}

@media (max-width: 479px){
    /* .banner-bottom .index ul{
        justify-content: center !important;
    } */
}

@media (max-width: 319px) {
    .banner h1 {
        font-size: 29px !important;
    }
}