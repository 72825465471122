.terms-conditions {
    min-height: 1080px;
    padding: 200px 0;
}

.terms-conditions h1 {
    letter-spacing: 3.76px;
    color: #FAFBFF;
    text-transform: uppercase;
    font-family: 'Formula Condensed';
    font-weight: bold;
    font-size: 74px;
    line-height: 98px;
}

.terms-conditions p,
.terms-conditions ul li {
    font: normal normal 300 26px/36px Assistant;
    letter-spacing: 0px;
    color: #FAFBFF;
}

.terms-conditions strong {
    font-family: 'Formula Condensed';
    font-weight: bold;
    font-size: 36px;
    letter-spacing: 2.88px;
    color: #FFFFFF;
    display: block;
    margin-bottom: 10px;
}

.mb-24 {
    margin-bottom: 45px;
}

.terms-conditions .card {
    min-height: 800px;
    padding: 50px;
}